@use "@wojo/ui/mixins" as *;

.local-kpd_chat {
    :global(#kpd_chat) {
        box-shadow: none !important;
    }

    @include breakpoint-sm {
        :global(#kpd_chat) {
            box-shadow: 0 0 10px 1px #808080 !important;
        }
    }
}
