.dialpad-button-wrapper {
    position: fixed;
    right: var(--g-spacing-lg);
    bottom: var(--g-spacing-sm);
    z-index: 20;

    & .dialpad-button {
        border-radius: var(--g-size-md);
        box-shadow: var(--s-shadow-down);
        --background-default: var(--c-chat-button-background-default);
        --background-active: var(--c-chat-button-background-active);
        --background-focus: var(--c-chat-button-background-focus);
        --background-disabled: var(--c-chat-button-background-disabled);
        --border-color-default: var(--c-chat-button-border-color-default);
        --border-color-active: var(--c-chat-button-border-color-active);
        --border-color-focus: var(--c-chat-button-border-color-focus);
        --border-color-disabled: var(--c-chat-button-border-color-disabled);
        --icon-color-default: var(--c-chat-button-icon-color-default);
        --icon-color-active: var(--c-chat-button-icon-color-default);
        --icon-color-focus: var(--c-chat-button-icon-color-default);
        --icon-color-disabled: var(--c-chat-button-icon-color-default);
        --outline-color-focus: var(--c-chat-button-outline-color-focus);
        --text-color-default: var(--c-chat-button-text-color-default);
        --text-color-active: var(--c-chat-button-text-color-default);
        --text-color-focus: var(--c-chat-button-text-color-default);
        --text-color-disabled: var(--c-chat-button-text-color-disabled);
    }
}
